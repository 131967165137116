#root {
  --primary: #00f29b;
  --gold: #ffd700;
  --white: #ffffff;
  --black: #000000;
  --green: green;
  font-family: 'Kufam' !important;
}

.font-prompt {
  font-family: 'Prompt' !important;
}

.font-kufam {
  font-family: 'Kufam' !important;
}

.text-medium {
  font-size: 24;
}

.row-hover:hover {
  background-color: rgba(220, 220, 220, 0.3);
}

button:hover {
  background-color: black !important;
}
